<script setup lang="ts">
import { useOrganizationStore } from '~/src/entities/organization';

const orgStore = useOrganizationStore();
</script>

<template>
  <el-menu router>
    <el-menu-item :index="`/${orgStore.organization?.id}/settings/general`">
      <el-icon><el-icon-school /></el-icon>
      Organization details
    </el-menu-item>
    <el-menu-item :index="`/${orgStore.organization?.id}/settings/billing`">
      <el-icon><el-icon-briefcase /></el-icon>
      Billing
    </el-menu-item>
    <el-menu-item :index="`/${orgStore.organization?.id}/settings/payments`">
      <el-icon><el-icon-money /></el-icon>
      Payments
    </el-menu-item>
  </el-menu>
</template>
